<template>

	<var-skeleton :rows="2" :loading="loading">
		<var-form ref="form">
			<var-input
				placeholder="Please input email"
				:rules="[v => !!v || 'The email cannot be empty']"
				v-model="formData.email"
				class="mb-3"
			/>
			<var-input
				type="password"
				placeholder="Please input password"
				:rules="[v => !!v || 'The password cannot be empty']"
				v-model="formData.password"
				class="mb-3"
			/>
		</var-form>

		<var-button @click="login" type="primary">Login</var-button>
	</var-skeleton>

  <div id="firebaseui-auth-container"></div>
</template>

<script>

import { reactive, ref } from 'vue'
import auth from "../auth"
import statics from "../static"
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

export default {
	name: "Login",
	setup() {
		const formData = reactive({
			email: null,
			password: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
  mounted() {
    firebase.initializeApp({
      apiKey: "AIzaSyDQ4Jh8IQ3JbDPfQXZsBZC-nke2e9AE1Xs",
      authDomain: "dotted-wind-377413.firebaseapp.com",
      projectId: "dotted-wind-377413",
      storageBucket: "dotted-wind-377413.appspot.com",
      messagingSenderId: "349338992761",
      appId: "1:349338992761:web:252d5a1591b90d9a8c54a8"
    })
    const uiConfig = {
      signInFlow: 'popup',
      signInSuccessUrl: '/dashboard',
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          clientId: '349338992761-492loiofmqnaluhm2rpn6vmebajorann.apps.googleusercontent.com'
        },
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
			let email = authResult.user.email
	        let provider = authResult.additionalUserInfo.providerId

	        auth.signIn({email, provider})
		        .then(response => {
			        this.loading = false

			        console.log(response)

			        let data = response.data
			        this.$store.commit("setUser", data)

			        if (response.status == 201) {
						alert('Create organization')
			        } else if (response.status == 200) {
				        localStorage.setItem('token', data.token)
				        this.$router.push({ name: "Dashboard" })
			        } else {
				        let key = data.key
				        this.$router.push({
					        name: "TwoFA",
					        params: {
						        auth_key: key
					        }
				        })
			        }
		        })
		        .catch(error => {
					this.loading = false
		        })

	        return false
        }
      }
    }

    const ui = new firebaseui.auth.AuthUI(firebase.auth())
    ui.start("#firebaseui-auth-container", uiConfig)
  },
	data() {
		return {
			loading: false
		}
	},
	methods: {
		login() {

			this.loading = true

			this.form.validate().then(validated => {
				if (!validated) {
					this.loading = false
					return
				}

				auth.login({email: this.formData.email, password: this.formData.password})
					.then(data => {
						this.loading = false
						this.$store.commit("setUser", data)
						if (data.token) {
							localStorage.setItem('token', data.token)
							this.$router.push({ name: "Dashboard" })
						} else {
							let key = data.key
							this.$router.push({
								name: "TwoFA",
								params: {
									auth_key: key
								}
							})
						}
					})
					.catch(error => {

						if (error.response.status === 302) {
							this.$router.push({
								name: "Verification",
								params: {
									email: this.email,
									resend: true
								}
							})
						} else {
							this.loading = false
							this.errorMessage = statics.user_not_found
						}
					})
			})
		}
	}
}
</script>

<style>

</style>